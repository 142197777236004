<template>
  <ul>
    <li
      v-for="(r, i) in responsesPossible"
      :key="i"
    >
      {{ r.libelle }}
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        object: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
          responses: 'sanitaire/responses'
        }),
        responsesPossible(){
          return [...this.responses].filter(item  => item.question === this.object.uid)
        }
    }
}
</script>

<style>

</style>